import React, { useContext } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { EnvContext } from "../../context/EnvContext";
import e from "cors";

const MainLayout = () => {
  const environment = useContext(EnvContext);

  const navigate = useNavigate();
  //
  const location = useLocation();
  const basePath = environment === "staging" ? "" : "";
  const isDevelopment = environment === "staging" ? true : false;

  const toggleEnvironment = () => {
    const newBasePath = isDevelopment ? "" : "";

    // Replace the base path in the current URL
    const newPath = location.pathname.replace(basePath, newBasePath);

    // List of routes where IDs are expected in the URL
    const routesWithId = ["/main/brands/edit", "/main/products/edit", "/main/stores/edit"];

    // Check if the current path includes any of the routes that expect an ID
    const expectsId = routesWithId.some((route) => newPath.includes(route));

    // Check if the last segment is an ID (20 characters long)
    const pathSegments = newPath.split("/");
    const lastSegmentIsId = pathSegments[pathSegments.length - 1].length === 20;

    // If the route expects an ID and the last segment is an ID, don't remove it
    if (!expectsId && lastSegmentIsId) {
      pathSegments.pop();
    }

    // Navigate to the new path
    navigate(pathSegments.join("/"));
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    navigate(`${basePath}/`);
  };

  return (
    <>
      <div
        style={{
          ...styles.header,
          backgroundColor: isDevelopment ? "#dff700" : "white",
        }}
      >
        <div
          style={{
            ...styles.logoContainer,
          }}
        >
          <Link to={`${basePath}/main`} style={styles.link}>
            <div style={{}}>
              <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ width: "100%" }} />
            </div>
          </Link>
        </div>
        <div style={styles.headerMenuContainer}>
          <div
            style={{
              display: "flex",
              paddingRight: "25px",
              paddingBottom: "10px",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                marginRight: "15px",
                borderRight: "1px solid #ddd2d5",
                paddingRight: "15px",
                display: "none",
              }}
            >
              <span style={{ marginRight: "10px" }}>{"Development"}</span>
              <label className="switch" style={{}}>
                <input type="checkbox" checked={isDevelopment} onChange={toggleEnvironment} />
                <span className="slider round"></span>
              </label>
            </div>
            <div>
              <div>Welcome, Admin</div>
              <div
                onClick={logout}
                style={{
                  padding: "0px",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.content}>
        <nav style={{ ...styles.navContainer, backgroundColor: "white" }}>
          <div style={{ padding: "10px" }}>
            <ul style={styles.ul}>
              <h3
                style={{
                  padding: "0px 0px 0px 10px",
                  color: "#808080",
                  fontWeight: "300",
                  color: "red",
                }}
              >
                Content
              </h3>
              <li style={styles.li}>
                <Link to={`${basePath}/main/brands`} style={styles.link}>
                  Brands
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/products/edit`} style={styles.link}>
                  Products
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/stores/edit`} style={styles.link}>
                  Sellers
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/listings`} style={styles.link}>
                  Listing
                </Link>
              </li>
              <li style={{ ...styles.li, display: "none" }}>
                <Link to={`${basePath}/main/orders`} style={styles.link}>
                  Orders
                </Link>
              </li>
              <li style={{ ...styles.li, display: "none" }}>
                <Link to={`${basePath}/main/venmo_pending_payments`} style={styles.link}>
                  Order Queue
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/orders`} style={styles.link}>
                  Orders
                </Link>
              </li>
              <li style={{ borderTop: ".5px solid #c2c2c2" }} />
              <h3
                style={{
                  padding: "0px 0px 0px 10px",
                  color: "#808080",
                  fontWeight: "300",
                  color: "red",
                }}
              >
                Shop Settings
              </h3>
              <li style={{ ...styles.li, display: "none" }}>
                <Link to={`${basePath}/main/media`} style={styles.link}>
                  Media
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/shipment`} style={styles.link}>
                  Shipment
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/payment_gateways`} style={styles.link}>
                  Payment Gateways
                </Link>
              </li>
              <li style={{ ...styles.li, display: "none" }}>
                <Link to={`${basePath}/main/newuserslimit`} style={styles.link}>
                  New Users Limit
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/dosingGuide`} style={styles.link}>
                  Dosing Guide
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/newsletter_subscribers`} style={styles.link}>
                  Newsletter subscribers
                </Link>
              </li>
              <li style={{ borderTop: ".5px solid #c2c2c2" }} />
              <h3
                style={{
                  padding: "0px 0px 0px 10px",
                  color: "#808080",
                  fontWeight: "300",
                  color: "red",
                }}
              >
                Marketing
              </h3>
              <li style={styles.li}>
                <Link to={`${basePath}/main/discount_codes`} style={styles.link}>
                  Discount Code
                </Link>
              </li>
              <li style={styles.li}>
                <Link to={`${basePath}/main/marketing_product`} style={styles.link}>
                  Custom Landing Page
                </Link>
              </li>
              <li style={{ borderTop: ".5px solid #c2c2c2" }} />
              <h3
                style={{
                  padding: "0px 0px 0px 10px",
                  color: "#808080",
                  fontWeight: "300",
                  color: "red",
                }}
              >
                Users
              </h3>
              <li style={styles.li}>
                <Link to={`${basePath}/main/users`} style={styles.link}>
                  Users List
                </Link>
              </li>
              <li style={{ borderTop: ".5px solid #c2c2c2" }} />
              <h3
                style={{
                  padding: "0px 0px 0px 10px",
                  color: "#808080",
                  fontWeight: "300",
                  color: "red",
                }}
              >
                Admins
              </h3>
              <li style={styles.li}>
                <Link to={`${basePath}/main/admins`} style={styles.link}>
                  Admins List
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div style={{ flex: 5, height: "100%" }}>
          <div style={{ padding: "10px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

// style
const styles = {
  header: { display: "flex", height: "60px", backgroundColor: "white" },
  logoContainer: {
    flex: 1,
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    minWidth: 220,
    justifyContent: "center",
    alignContent: "center",
    fontSize: "24px",
    fontWeight: "200",
    backgroundColor: "white",
    color: "white",
  },
  headerMenuContainer: {
    flex: 5,
    height: "100%",
    borderBottom: "1px solid #ccc",
    alignItems: "flex-end",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    textAlign: "right",
  },
  content: { display: "flex", height: "100%" },
  navContainer: {
    flex: 1,
    borderRight: "1px solid white",
    height: "100%",
    minWidth: 220,
    backgroundColor: "white",
    color: "black",
    borderRight: ".5px solid #808080",
  },
  link: {
    color: "black",
    textTransform: "uppercase",
    textDecoration: "none",
    fontFamily: "Space Grotesk",
  },
  activeLink: { color: "red", fontWeight: "800", textDecoration: "underline" },
  ul: { listStyle: "none", padding: 0 },
  li: { listStyle: "none", padding: "10px 10px" },
};

export default MainLayout;
