import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";

//
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { FirestoreContextProvider } from "./components/firebase/FirestoreContext";
import { useUser } from "./components/context/UserContext";
//
import Login from "./components/screens/Login";
import MainLayout from "./components/screens/MainLayout";
// Brands
import Brands from "./components/screens/Brands";
import NewBrand from "./components/screens/Brands/NewBrand";
import EditBrand from "./components/screens/Brands/EditBrand";

import Media from "./components/screens/Media";

// Products
import Products from "./components/screens/Products";
import NewProduct from "./components/screens/Products/NewProduct";
import EditProduct from "./components/screens/Products/EditProduct";
import UploadCSVProduct from "./components/screens/Products/UploadCSVProduct";

// Stores
import Stores from "./components/screens/Stores";
import NewStore from "./components/screens/Stores/NewStore";
import EditStores from "./components/screens/Stores/EditStore";
import NewUsersLimit from "./components/screens/NewUsersLimit";

// Listings
import Listings from "./components/screens/Listings";
import EditListings from "./components/screens/Listings/EditListings";
import SooperDeals from "./components/screens/Listings/SooperDeals";

// DosingGuide

import DosingGuide from "./components/screens/DosingGuide";
import EditDosingGuide from "./components/screens/DosingGuide/EditDosingGuide";
import NewDosingGuide from "./components/screens/DosingGuide/NewDosingGuide";

// Newsletter
import NewsletterSubscribers from "./components/screens/Newsletter/NewsletterSubscribers";

// Marketing
import MarketingProduct from "./components/screens/Marketing/MarketingProduct";
import DiscountCodes from "./components/screens/Marketing/DiscountCodes";

// Users
import Users from "./components/screens/Users";
import EditUser from "./components/screens/Users/EditUser";

//
import Shipment from "./components/screens/Shipment";

// Orders
import Orders from "./components/screens/Orders";

// Venmo
import VenmoPendingPayments from "./components/screens/Venmo/VenmoPendingPayments";

import NotFound from "./components/screens/404";

// Admins
import Admins from "./components/screens/Admins";

//
import PaymentGateways from "./components/screens/PaymentGateways";

// Firestore config
const firebaseConfig = {
  apiKey: "AIzaSyC5JFSlefBl7pnJysUl4iEmRVp3S5KM8X0",
  authDomain: "sooperstock-app.firebaseapp.com",
  projectId: "sooperstock-app",
  storageBucket: "sooperstock-app.appspot.com",
  messagingSenderId: "277475901921",
  appId: "1:277475901921:web:301af934e1046d2cfe12a0",
  measurementId: "G-6E04Z8KFTD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

const App = () => {
  return (
    <FirestoreContextProvider db={db} functions={functions}>
      <Router>
        <AppRoutes />
      </Router>
    </FirestoreContextProvider>
  );
};

// Separate component for routes, to use useEnvironment inside Router
const AppRoutes = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Login />} />
      <Route path={`/main`} element={<MainLayout />}>
        <Route path="brands" element={<Brands />}>
          <Route path="new" element={<NewBrand />} />
          <Route path="edit" element={<EditBrand />} />
          <Route path="edit/:id" element={<EditBrand />} />
        </Route>
        <Route path="media" element={<Media />} />
        <Route path="products" element={<Products />}>
          <Route path="new" element={<NewProduct />} />
          <Route path="edit" element={<EditProduct />} />
          <Route path="edit/:id" element={<EditProduct />} />
          <Route path="uploadcsv" element={<UploadCSVProduct />} />
        </Route>
        <Route path="listings" element={<Listings />}>
          <Route path="edit/:id" element={<EditListings />} />
          <Route path="sooper-deals/:id" element={<SooperDeals />} />
        </Route>
        <Route path="orders" element={<Orders />}>
          <Route path=":seller_id" element={<Orders />}>
            <Route path=":id" element={<Orders />} />
          </Route>
        </Route>
        <Route path="stores" element={<Stores />}>
          <Route path="new" element={<NewStore />} />
          <Route path="edit" element={<EditStores />} />
          <Route path="edit/:id" element={<EditStores />} />
        </Route>
        <Route path="dosingGuide" element={<DosingGuide />}>
          <Route path="new" element={<NewDosingGuide />} />
          <Route path="edit" element={<EditDosingGuide />} />
          <Route path="edit/:id" element={<EditDosingGuide />} />
        </Route>
        <Route path="discount_codes" element={<DiscountCodes />} />

        <Route path="shipment" element={<Shipment />} />
        <Route path="NewUsersLimit" element={<NewUsersLimit />} />
        <Route path="venmo_pending_payments" element={<VenmoPendingPayments />} />
        <Route path="newsletter_subscribers" element={<NewsletterSubscribers />} />
        <Route path="marketing_product" element={<MarketingProduct />} />
        <Route path="users" element={<Users />} />
        <Route path="users/edit/:id" element={<EditUser />} />
        <Route path="admins" element={<Admins />} />
        <Route path="payment_gateways" element={<PaymentGateways />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
